<template>
  <div class="menu_background"/>
  <div id="menu">
      <ul class="menu_items">
          <li><h1>Game Paused</h1></li>
          <li><MenuButton text="RESUME" @clicked="resume"/></li>
          <li><MenuButton text="RESET" @clicked="reset"/></li>
          <li><MenuButton text="SETTINGS"/></li>
          <li><MenuButton text="BACK TO MENU" @clicked="backToMenu"/></li>
      </ul>
  </div>
</template>

<script>
import MenuButton from './MenuButton.vue'
export default {
    components:{MenuButton},
    emits: ['resume', 'reset', 'backToMenu'],
    setup(props, {emit}){
        const resume = () =>{
            emit('resume')
        }
        const reset = () =>{
            emit('reset')
        }

        const backToMenu = () =>{
            emit('backToMenu')
        }
        return {resume, reset, backToMenu}
    }
}
</script>

<style>
.menu_background {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(3px);
    z-index: 3;
}
#menu{
    position:fixed;
    width: 100vw;
    height: 100vh;  
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.menu_items{
    margin:0;
    padding:0;
}

ul li{
    display: flex;
    justify-content: center;
}

</style>