<template>
    <div class="zombie-stats-panel">
        <!-- <DisplayPanelTitle title="Browking Dead"/> -->
        <div class="zombie-kd">
            <DisplayPanelKDAuto
            mode="zombie"
            :newStats="newStats"
            :oldStats="oldStats"
            />
            <DisplayPanelKDAuto
            title="K/D (Infected)"
            mode="zombieZ"
            :newStats="newStats"
            :oldStats="oldStats"
            />
        </div>
        <DisplayPanelWinrateH
        title="Escape %"
        mode="zombie"
        :newStats="newStats"
        :oldStats="oldStats"
        />
    </div>
</template>

<script>
import DisplayPanelTitle from "./DisplayPanelTitle.vue"
import DisplayPanelKDAuto from "./DisplayPanelKDAuto.vue"
import DisplayPanelWinrateH from "./DisplayPanelWinrateH.vue"
export default{
    components: {DisplayPanelTitle, DisplayPanelKDAuto, DisplayPanelWinrateH},
    props:['newStats', 'oldStats'],
    setup(props){

    }
}
</script>

<style>
.zombie-stats-panel{
    margin: 5px 0 5px 0;
}
.zombie-stats-panel > div:not(:first-child){
    margin-top: 10px;
}

.zombie-kd{
    display: flex;
    margin: 0;
    padding: 0;
}

.zombie-kd > *{
    flex-basis: 0;
    flex-grow: 1;
}
</style>