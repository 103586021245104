<template>
    <div class="panel-winrate">
        <div class="winrate-items">
            <div class="winrate">
                <LabelFeaturedStat
                    :title=title
                    iconURL="/ui_icons/KillFeedKill.png"
                    :value="statWinrate"
                    valueEnd="%"
                    :trend="statWinrateTrend"

                />
                <LabelGeneralStat
                    :title="getTitle(winKey)"
                    :value="getValue(winKey, newStats)"
                    :trend="getTrend(winKey, newStats, oldStats)"
                    :rank="getRank(winKey, newStats)"
                />
                <LabelGeneralStat
                        :title="getTitle(gameKey)"
                        :value="getValue(gameKey, newStats)"
                        :trend="getTrend(gameKey, newStats, oldStats)"
                        :rank="getRank(gameKey, newStats)"
                />
            </div>
            <div v-if="haveTopRate" class="toprate">
                <LabelFeaturedStat
                    :title="topTitle"
                    iconURL="/ui_icons/KillFeedKill.png"
                    :value="statToprate"
                    valueEnd="%"
                    :trend="statToprateTrend"
                />
                <LabelGeneralStat
                    :title="getTitle(topKey)"
                    :value="getValue(topKey, newStats)"
                    :trend="getTrend(topKey, newStats, oldStats)"
                    :rank="getRank(topKey, newStats)"
                />
            </div>
       </div>
    </div>
</template>
<script>
import LabelFeaturedStat from './LabelFeaturedStat.vue'
import LabelGeneralStat from './LabelGeneralStat.vue'
import { getTitle } from './statTitles'
import { getValue, getRank, getTrend } from './statUtils'

import {computed} from '@vue/runtime-core'
export default {
    components: {LabelFeaturedStat, LabelGeneralStat},
    props: {
        title:{
            default: "Win %"
        },
        mode:{},
        newStats:{},
        oldStats:{},
    },
    setup(props){   
        const winKey = computed(()=>{
            switch(props.mode){
                case 'solo':
                    return 'Wins'
                case 'duos':
                    return 'WinsDuos'
                case 'squads':
                    return 'WinsSquads'
                case 'svr32':
                    return 'WinsCustom1'
                case 'svr24':
                    return 'WinsCustom1B'
                case 'mystery':
                    return 'WinsCustom2'
                case 'zombie':
                    return 'WinsCustom3'       
            }
        })
        const topKey = computed(()=>{
            switch(props.mode){
                case 'solo':
                    return 'Top5'
                case 'duos':
                    return 'Top3Duos'
                case 'squads':
                    return 'Top2Squads'
                case 'mystery':
                    return 'Top2Custom2'       
            }
        })
        const topTitle = computed(()=>{
            switch(props.mode){
                case 'solo':
                    return 'Top 5 %'
                case 'duos':
                    return 'Top 3 %'
                case 'squads':
                    return 'Top 2 %'
                case 'mystery':
                    return 'Top 2 %'      
            }
        })
        const gameKey = computed(()=>{
            switch(props.mode){
                case 'solo':
                    return 'Games'
                case 'duos':
                    return 'GamesDuos'
                case 'squads':
                    return 'GamesSquads'
                case 'svr32':
                    return 'GamesCustom1'
                case 'svr24':
                    return 'GamesCustom1B'
                case 'mystery':
                    return 'GamesCustom2'
                case 'zombie':
                    return 'GamesCustom3'       
            }
        })
        const haveTopRate = computed(()=>{
            const disabledMode = ['svr32', 'svr24', 'zombie']
            return !disabledMode.includes(props.mode)
        })

        const getWinrate = (stats)=>{
            if(stats != undefined){
                return (getValue(gameKey.value, stats) > 0)?
                    (getValue(winKey.value, stats) / getValue(gameKey.value, stats)*100):0
            }
            return 0
        }

        const getToprate = (stats)=>{
            if(stats != undefined){
                return (getValue(gameKey.value, stats) > 0)?
                    (getValue(topKey.value, stats) / getValue(gameKey.value, stats)*100):0
            }
            return 0
        }
    
        const statWinrate = computed(()=>{
            return getWinrate(props.newStats).toFixed(2)
        })
        const statToprate = computed(()=>{
            return getToprate(props.newStats).toFixed(2)
        })

        const statWinrateTrend = computed(()=>{
            // If oldStat is added
            if(props.oldStats != undefined){
                return (getWinrate(props.newStats) - 
                        getWinrate(props.oldStats)).toFixed(2)
            }
            return (0).toFixed(2)
        })
        const statToprateTrend = computed(()=>{
            // If oldStat is added
            if(props.oldStats != undefined){
                return (getToprate(props.newStats) - 
                        getToprate(props.oldStats)).toFixed(2)
            }
            return (0).toFixed(2)
        })

        return {
            winKey,
            topKey,
            topTitle,
            gameKey,

            statWinrate,
            statWinrateTrend,
            statToprate,
            statToprateTrend,

            haveTopRate,

            getTitle,
            getValue,
            getRank,
            getTrend}
    }
}
</script>
<style>
.panel-winrate{
    width: auto;
    min-width: min-content;
    margin: 0 10px 0 10px;
    background: rgb(250,250,250, 0.75);
    padding:1.1rem;
    border-radius: 10px;
}
.winrate-items{
    display: flex;
    flex-shrink: 0;
}
.winrate{
    width: 200px;
}
.toprate{
    width: 200px;
    margin-left: 1.25rem;
}

.winrate-items> * > div:not(:first-child){
    margin-bottom: 0.3rem;
}
</style>