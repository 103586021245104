<template>
    
    <div class="stats-panel-title">
        <div class="stats-panel-title-text">{{title}}</div>
    </div>
</template>
<script>
import MenuButton from '../../menus/MenuButton.vue';
export default{
    props: ["title"],
    setup(props) {
    },
    components: { MenuButton }
}
</script>
<style>
.stats-panel-title{
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    position: static;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #3BAEAA;
    text-align: left;
    align-items: center;
    text-transform: uppercase;
    margin-left: 10px;
    
}

.stats-panel-title-text{
    display: inline-block;
    background: rgb(250,250,250, 0.75);
    min-width: 150px;

    margin: 0;
    padding: 0.8rem;
    border-radius: 5px;

    border-left: 5px solid rgb(59, 169, 184);;
}
</style>