<template>
  <div id="UISearchbar">
        <input v-model="searchText" id="searchText" :placeholder="hint"/> 
        <button id="searchButton" @click="searchClicked"><i class="gg-search"></i></button>
  </div>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"
export default {
    props: ['hint'],
    setup(props, {emit}){
        const router = useRouter()
        const searchText = ref('')
        const resolveSteam = () => {
            var requestBody = {
                'ProfileLinkorID' : searchText.value
            }
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'text';
            xhr.open("POST", "http://127.0.0.1:3000/ResolveSteamID64", true);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.onload = function () {
                var resJSON = JSON.parse(this.responseText)
                if("data" in resJSON){
                    var st64 = resJSON['data']['SteamID64']
                    router.push({ name: 'PlayerStats', params: { id: st64 }})
                }
                else{
                    alert('Steam User not found')
                }
            }
            xhr.onerror = function () {
                alert("Connection Error");
            };
            xhr.send(JSON.stringify(requestBody));
        }


        const searchClicked = () =>{ 
            if(searchText.value != ''){resolveSteam();}
            else{alert("Search Field is Empty")}     
        }
        return {searchClicked, searchText}
    }
}
</script>

<style>
@import url('https://css.gg/search.css');
.gg-search {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,0.8));
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-radius: 100%;
    color:rgb(255, 255, 255);
    margin-left: -4px;
    margin-top: -2px;
    transition: all 0.1s ease-in-out;
}

#UISearchbar{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding:0;
    margin: 0;
    display:flex;
}
#searchText{
    background:white;
    min-height: 0.6rem;
    min-width: 500px;

    border-radius: 10px 0 0 10px;
    border: none;
    padding: 0.6rem 1.2rem;
}
input:focus{
    outline: none;
}
#searchButton{
    width: 50px;
    display:flex;
    justify-content: center;
    background: rgb(217, 93, 57);
    border-radius: 0 10px 10px 0;
    border: none;
    padding:0.6rem;
    margin: 0;
    transition: all 0.1s ease-in-out;
    
}
#searchButton:hover, #searchButton:hover i {
    background: rgb(55, 150, 84);
    color:rgb(23, 30, 52);
    cursor: pointer;
}

</style>