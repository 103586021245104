<template>
  <div id="background">
    <div class="background-video">
      <video v-if="{video}!=''" autoplay muted loop>
          <source :src="video_path" type="video/mp4">
      </video>
    </div>
    
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
export default {
  props: {
    background:{type: String, default: ""},
    video:{type: String, default: ""},
    opacity:{type: Number, default: 1}
  },
  setup(props){
    const bg_path = computed(()=>{
      return props.background!="" && props.video=="" ? "url(\"/background/" +  props.background : "none"
    })
    const video_path = computed(()=>{
      return props.video!="" ? "background/video/" +  props.video : ""
    })
    const bg_color = computed(()=>{
      return props.background!="" || props.video!=""? "black" : "rgb(53, 83, 65)"
    })
    const bg_opacity = computed(()=>{
      return props.opacity
    })

    return {bg_path, video_path, bg_color, bg_opacity}
  }
}
</script>

<style>
#background {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -5;

    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    background-image: v-bind(bg_path);
    background-color: black;
    background-color: v-bind(bg_color);
    background-image: v-bind(bg_path);
}

.background-video{
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    max-height: none;
    max-width: none;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
}
</style>