const keyTitles = {
            "Kills" : "Kills",
            "Deaths" : "Deaths",
            "Games" : "Games Played",
            "Wins" : "Wins",
            "Top5" : "Top5",
            "MostKills" : "Most Kills",
            "PacifistVictoriesSolo" : "Pacifist Victories",
            "MeleeVictoriesSolo" : "Melee Only Victories",
            "NoArmorVictoriesSolo" : "No Armor Victories",

            "KillsDuos" : "Kills",
            "DeathsDuos" : "Deaths",
            "GamesDuos" : "Games Played",
            "WinsDuos" : "Wins",
            "Top3Duos" : "Top3",
            "MostKillsDuos" : "Most Kills",

            "KillsSquads" : "Kills",
            "DeathsSquads" : "Deaths",
            "GamesSquads" : "Games Played",
            "WinsSquads" : "Wins",
            "Top2Squads" : "Top2",
            "MostKillsSquads" : "Most Kills",

            "KillsCustom1" : "Kills",
            "DeathsCustom1" : "Deaths",
            "FlagCaps" : "Flag Captured",
            "GamesCustom1" : "Games Played",
            "WinsCustom1" : "Wins",
            "MostKillsCustom1" : "Most Kills",

            "KillsCustom1B" : "Kills",
            "DeathsCustom1B" : "Deaths",
            "FlagCapsB" : "Flag Captured",
            "GamesCustom1B" : "Games Played",
            "WinsCustom1B" : "Wins",
            "MostKillsCustom1B" : "Most Kills",

            "KillsCustom2" : "Kills",
            "DeathsCustom2" : "Deaths",
            "GamesCustom2" : "Games Played",
            "WinsCustom2" : "Wins",
            "Top2Custom2" : "Top2",
            "MostKillsCustom2" : "Most Kills",

            "KillsCustom3" : "Kills",
            "DeathsCustom3" : "Deaths",
            "KillsCustom3Z" : "Kills",
            "DeathsCustom3Z" : "Deaths",
            "GamesCustom3" : "Games Played",
            "WinsCustom3" : "Wins",
            "MostKillsCustom3" : "Most Kills",
            "MostKillsCustom3Z" : "Most Kills",
            

            "DamageDealt" : "Damage Dealt",
            "EnemyArmorBroken" : "Enemy Armor Broken",
            "SkunkBombDamageDealt" : "Skunk Bomb Damage Dealt",
            "Healing" : "Healing",
            "DestructiblesDestroyed" : "Items Destroyed",
            "BananaHits" : "All Banana Slips",
            "BananaHitsEnemyOnly" : "Enemy Banana Slips",

            "KillsAK" : "AK kills",
            "KillsM16" : "M16 Kills",
            "KillsSMG" : "SMG Kills",
            "KillsThomas" : "Thomas Gun Kills",
            "KillsShotgun" : "Shotgun Kills",
            "KillsJag7" : "JAG7 Kills",
            "KillsMagnum" : "Magnum Kills",
            "KillsDeagle" : "Deagle Kills",
            "KillsHuntingRifle" : "Hunting Rifle Kills",
            "KillsSniper" : "Sniper Kills",
            "KillsPistol" : "Pistol Kills",
            "KillsDualPistol" : "Dual Pistol Kills",
            "KillsSilencedPistol" : "Scilenced Pistol Kills",
            "KillsBow" : "Bow and Sparrow Kills",
            "KillsCrossbow" : "Crossbow Kills",
            "KillsDart" : "Dartgun Kills",
            "KillsMinigun" : "Minigun Kills",
            "EmuKills" : "Emu Kills",
            "VehicleKills" : "Hamster Ball Kills",
            "MeleeKills" : "Melee Kills",
            "GrenadeKills" : "Grenade Kills",

            "TimePlayedSeconds" : "Playtime",
            "DistanceTraveled" : "Distance Travelled",
            "DistanceTraveledIceW2" : "Distance Travelled on Ice",
            "HealthJuiceDrank" : "Health Juice Drank",
            "TapeUsed" : "Super Tape Used",
            "SuperJumpRolls" : "Super Jump Rolls",
            "ParachuteSplats" : "Parachute Splats",
            "MoleCrates" : "Mole Crates Opened",
            "ChestOpens" : "Chest Opened",
            "CoconutsAte" : "Coconuts Ate",
            "MushroomsAte" : "Mushrooms Ate",
            "Scarecrows" : "Scarecrows Destroyed",
            "GrassCut" : "Grass Cut",
            "CampfiresUsed" : "Campfire Used",
            "CrabDance" : "Crab Dance",
            "BananOfferings" : "Banan Offereings",
            "RatGG" : "Rat GG",
            "GalleryTargetsHit" : "Gallerey Target Hits",
            "GalleryTargetsHitBow" : "Gallerey Target Hits with Bow",
            "BeGalleryTarget" : "Be Gallerey Target",
            "BowHitsOneMatch" : "Bow Hits in One Match",
            "ChickenWins" : "Winner Winner Super Chicken",
            "TurkeyWins" : "Winner Winner Super Turkey"
}

function getTitle(key){
    return key != undefined && keyTitles[key] != undefined ? keyTitles[key]: key
}
  export {getTitle}