<template>
  <Background background="background.jpg"
  video="background_video1.mp4"/>
  <div class="about">
  <StatsPanelGameMode
  :newStats="newStats"
  :oldStats="oldStats"
  />

  <!-- <DisplayPanelKDAuto
  mode="solo"
  :newStats="newStats"
  :oldStats="oldStats"
  /> -->

  </div>
</template>


<script>
import Background from "../components/Background.vue"
import Header from "../components/Header.vue"
import ResultSummary from "../components/menus/ResultSummary.vue"
import SettingsMenu from "../components/menus/SettingsMenu.vue"
import { onMounted, ref, computed} from '@vue/runtime-core'

import StatsPanelGameMode from "../components/UIComponents/Stats/StatsPanelGameMode.vue"
import DisplayPanelKDAuto from "../components/UIComponents/Stats/DisplayPanelKDAuto.vue"

export default {
  components: {Background, Header, ResultSummary, SettingsMenu, StatsPanelGameMode, DisplayPanelKDAuto},
  setup(){
    const newData = ref(undefined)
    const oldData = ref(undefined)
    const newStats = computed(()=>{
      return newData.value != undefined ? newData.value.Stats: undefined
    })
    const oldStats = computed(()=>{
      return oldData.value != undefined ? oldData.value.Stats: undefined
    })

    
    onMounted(()=>{
      newData.value = require('./test_data/newData.json')
    })
    return {newStats, oldStats}
  }
}
</script>

<style>
.about::-webkit-scrollbar {
  display: none;
}
.about{
  height: 100vh;
  overflow-y: scroll;
}
</style>
