<template>
    <div class="duos-stats-panel">
        <!-- <DisplayPanelTitle title="Duos"/> -->
        <div class="duos-kd-winrate">
        <DisplayPanelKD
        mode="duos"
        :newStats="newStats"
        :oldStats="oldStats"
        />
        <DisplayPanelWinrate class="winrate-panel"
        mode="duos"
        :newStats="newStats"
        :oldStats="oldStats"
        />
        </div>
    </div>
</template>

<script>
import DisplayPanelTitle from "./DisplayPanelTitle.vue"
import DisplayPanelKD from "./DisplayPanelKD.vue"
import DisplayPanelWinrate from "./DisplayPanelWinrate.vue"
export default{
    components: {DisplayPanelTitle, DisplayPanelKD, DisplayPanelWinrate},
    props:['newStats', 'oldStats'],
    setup(props){

    }
}
</script>

<style>
.duos-stats-panel{
    margin: 5px 0 5px 0;
}
.duos-stats-panel > div:not(:first-child){
    margin-top: 10px;
}
.duos-kd-winrate{
  display: flex;
  padding: 0;
}
.winrate-panel{
    flex-grow: 1;
}
</style>