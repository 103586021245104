<template>
  <div class="settings_background"/>
  <div id="settings">
      <div class="settingsTitle">GAME PAUSED</div>
      <div class="settingsItems">
          <div class="settingsButtons">
            <MenuButton class="settingsButton" text="BACK TO MENU" @clicked="backToMenu"/>
            <MenuButton class="settingsButton" text="RESUME" @clicked="resume"/>
          </div>
      </div>
  </div>
</template>

<script>
import MenuButton from './MenuButton.vue'
export default {
    components:{MenuButton},
    emits: ['resume', 'backToMenu'],
    setup(props, {emit}){
        const resume = () =>{
            emit('resume')
        }
        const backToMenu = () =>{
            emit('backToMenu')
        }
        return {resume, backToMenu}
    }
}
</script>

<style>
.settings_background {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(3px);
    z-index: 3;
}
#settings{
    font-family: 'Rubik', sans-serif;
    position: absolute;
    min-width: 400px;
    bottom:50%;
    left: 50%;
    transform: translate(-50%, 50%);
    background: rgba(0,0,0,0.6);
    backdrop-filter: blur(3px);
    padding: 1rem 1.6rem;
    border-radius: 0.8em;
    z-index: 3;
}

.settingsTitle{
    font-size: 2em;
    font-weight: bold;
    font-stretch: extra-condensed;
    text-align: left;
    margin: 1.5rem 0 1.5rem 0.7rem;
}

.settingsButtons{
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.settingsButton{
    margin: 0.7rem;
}


</style>