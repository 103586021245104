<template>
    <div class="general-stats-small">
        <div class="general-title-small">{{title}}</div>
        <div class="general-stat-small">
            <LabelGeneralStat v-for="(statKey) in statsKeyList" class="general-stat-small-label"
                :title="getTitle(statKey)"
                :value="getValue(statKey, newStats)"
                :trend="getTrend(statKey, newStats, oldStats)"
                :rank="getRank(statKey, newStats)"
            />
        </div>
    </div>
</template>

<script>
import LabelGeneralStat from './LabelGeneralStat.vue'
import { getTitle } from './statTitles'
import { getValue, getRank, getTrend } from './statUtils'
export default{
    components: {LabelGeneralStat},
    props:['title', 'iconURL', 'statsKeyList',
            'newStats', 'oldStats'],
    setup(props){
        return {
            getTitle,
            getValue, 
            getRank, 
            getTrend
        }
    }
}
</script>

<style>
.general-stats-small{
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    position: static;
    display: block;
    color: #3BAEAA;
    background: rgb(250,250,250, 0.75);
    margin: 0 10px 0px 10px;
    padding:1.1rem;
    border-radius: 10px;
}

.general-title-small{
    font-size: 1.4rem;
    padding: 0.2rem;
    text-align: left;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.general-stat-small{
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
}

.general-stat-small-label{
    min-width: 12.1rem;
    margin-right: 0.4rem;;
}
</style>