<template>
    <div class="stat-tab-btn" @click="clicked">
        <span class="stat-tab-title">{{ title }}</span>
    </div>
</template>
<script>
import { computed } from '@vue/runtime-core'
export default {
    props: ['title', 'iconURL', 'active'],
    emits: ['clicked'],
    setup(props, {emit}){
        const clicked = ()=>{
            emit('clicked')
        }
    
        const titleIconContent = computed(()=>{
            return (props.iconURL != undefined)?
            "\'\'": "none"
        })
        const titleIconURL = computed(()=>{
            return (props.iconURL != undefined)?
            ('url(\"'+props.iconURL+'\")'): 'none'
        })

        const isActive = computed(()=>{
            if (props.active != undefined){
                return props.active ? 11:5
            }
        })

        return {clicked, 
        titleIconContent,
        titleIconURL,
        isActive}

        
    }
}
</script>

<style>
.stat-tab-btn{
    font-family: 'Rubik', sans-serif;
    background: rgba(217, 93, 57);
    padding: 0.5rem 1rem 0.8rem 1rem;
    display: inline-block;
    border-radius: 10px 10px 0px 0px;
    
    /* No Select on Text */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    z-index: v-bind('isActive');
    transition: transform 0.2s;
}

.stat-tab-btn:hover{
    z-index: 11;
    transform: translateY(-0.2rem);
}

.stat-tab-title{
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stat-tab-title::before{
    content: v-bind('titleIconContent');
    background-image: v-bind('titleIconURL');
    background-repeat: no-repeat;
    background-size: 1.4rem;
    min-width: 1.4rem;
    min-height: 1.4rem;
    display: block;
    /* padding-right: 0.2rem; */
    position: relative;
    left: -0.2rem;
}

</style>