class SoundEffectPlayerVar{
    constructor(dir, soundVariations, volumeScale){
        var audio_path = ''
        if(soundVariations >= 0){
          audio_path = dir+"/" + Math.ceil(Math.random() *soundVariations) + ".wav"         
        }
        else{
          audio_path = dir+"/1.wav"
        }
        this.audio = new Audio(audio_path)
        this.audio.volume = volumeScale
        this.audio.play()
    }
  }

class SoundEffectPlayer{
  constructor(dir, name, volumeScale){
      var audio_path = ''
      audio_path = dir+"/" + name         
      this.audio = new Audio(audio_path)
      this.audio.volume = volumeScale
      this.audio.play()
  }
}

export default {SoundEffectPlayerVar, SoundEffectPlayer}
