<template>
  <Background/>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <h1>Hello World</h1>
    <p>This is Home component</p> -->
    <Header/>

    <!-- UI Panel Components -->
    <MainMenu v-if="menu_main" 
    @startFreePlay="initFreePlay"
    @startChallenge="initChallenge"
    />
    
    <PauseMenu v-if="menu_pause"
    @resume="pauseMenuUI"
    @reset="resetGamemode"
    @backToMenu="initGame"
    />

    <ResultSummary v-if="menu_result"
    @backToMenu="initGame"

    :timerElapsed="timerElapsed"
    :target_hit="target_hit"
    :target_hit_near="target_hit_near"
    :accuracy="accuracy"
    :kpm="kpm"
    :score="score"
    :shots_fired="shots_fired"
    
    />


    <!-- App Component -->
    <Trainer 
    :shots_fired="shots_fired"
    :game_paused="game_paused"
    :timerIsForceEnd="timerIsForceEnd"

    @scoreUpdate="updateScore" 
    @shotsUpdate="updateShotsFired"
    @timerUpdate="updateTimeElapsed"
    @initEndResult="initEndResult"

    />

    <Footer 
    :score="score" 
    :target_hit="target_hit"
    :target_hit_near="target_hit_near"
    :shots_fired="shots_fired"
    :accuracy="accuracy"
    :kpm="kpm"

    :timerElapsed="timerElapsed"
    :timerCountdown="timerCountdown"
    />
  </div>
</template>

<script>
import {ref, computed, onMounted, onUnmounted} from 'vue'
import Background from "../components/Background.vue"
import Header from "../components/Header.vue"
import Trainer from "../components/trainer/Trainer.vue"
import Footer from "../components/Footer.vue"

// Import Menu Components
import MainMenu from '../components/menus/MainMenu.vue'
import PauseMenu from '../components/menus/PauseMenu.vue'
import ResultSummary from '../components/menus/ResultSummary.vue'

export default {
  name: 'Home',
  components: { Background, Header, Trainer, Footer, MainMenu, PauseMenu, ResultSummary },
  setup(){
    // Consts
    const challengeTime = 60

    // Stats
    const score = ref(0)
    const target_hit = ref(0)
    const target_hit_near = ref(0)
    const shots_fired = ref(0)
    const timeElapsed = ref(0)

    const resetStats = () =>{
      score.value = 0
      target_hit.value = 0
      target_hit_near.value = 0
      shots_fired.value = 0
      timeElapsed.value = 0
    }

    // Game State
    const gameHasStarted = ref(false)
    const gameMode = ref(0)
    const game_paused = computed(()=>{
      return menu_main.value || menu_pause.value || menu_result.value
    })
    
    // UI State
    const menu_main = ref(true)
    const menu_pause = ref(false)
    const menu_result = ref(false)

    // Game State func
    const initGame = ()=>{
      menu_pause.value = false
      menu_result.value = false
      menu_main.value = true
      gameHasStarted.value = false
    }
    const initFreePlay = () =>{
      menu_main.value = false
      resetStats()
      gameMode.value = 1
      timeLimit.value = -1
      gameHasStarted.value = true
    }

    const initChallenge = () =>{
      menu_main.value = false
      resetStats()
      gameMode.value = 2
      timeLimit.value = challengeTime
      gameHasStarted.value = true
    }

    const resetGamemode = () =>{
      if(gameHasStarted.value){
        menu_pause.value = false
        switch(gameMode.value){
          case 1:
            initFreePlay()
            break;
          case 2:
            initChallenge()
            break;
          default:
            console.log('Game mode reset')
        }
      }
    }

    const initEndResult = ()=>{
      menu_result.value = true
    }
      
    
    // Stat func
    const accuracy = computed(()=>{
      return (shots_fired.value > 0)?
      (((target_hit.value+target_hit_near.value*0.5) / shots_fired.value)*100).toFixed(2)+"%":("0.00%")
    })
    const updateScore = (score_change, hit_type) => {
      switch(hit_type){
        case 2:
          target_hit.value += 1
          break;
        case 1:
          target_hit_near.value += 1
          break;
      }
      score.value += score_change
      // console.log('Home component:' + score.value)
    }
    const updateShotsFired = () => {
      shots_fired.value += 1
      // console.log('Home component:' + score.value)
    }

    const kpm = computed(()=>{
      if(timeElapsed.value <= 0){
        return 0
      }
      return (target_hit.value / (timeElapsed.value / 60)).toFixed(2)
    })
    
    // Timer Var
    const timeLimit = ref(-1)
    // Timer Func
    const updateTimeElapsed = (time) => {
      timeElapsed.value += time
      //console.log(timeElapsed.value)
    }
    const _second = 1
    const _minute = _second * 60
    const timerElapsed = computed(()=>{
        var delta = timeElapsed.value
        var minutes = Math.floor(delta/_minute)
        var seconds = Math.floor((delta%_minute)/_second)
        var displayMinutes = minutes < 10 ? "0"+minutes : minutes
        var displaySeconds = seconds < 10 ? "0"+seconds : seconds
        return displayMinutes+":"+displaySeconds
      })
    const timerCountdown = computed(()=>{
        var timeLeft = timeLimit.value - timeElapsed.value
        if(timeLeft>0){
          var displaySeconds = Math.ceil(timeLeft)
          return displaySeconds
        }
        return 0
        
      })
    const timerIsForceEnd = computed(()=>{
      if(timeLimit.value >= 0 && timeElapsed.value >= timeLimit.value){
        return true
      }
      return false
    })


    // Menu Visibility Toggler
    const pauseMenuUI = () =>{
      menu_pause.value = !menu_pause.value
      console.log('pause')
    }
    // Event Wrapper
    const escListener = (event) =>{
      if(event.code == 'Escape' && gameHasStarted.value){
        if(menu_result.value && !menu_pause.value){
          return
        }
        pauseMenuUI()
      }
      //console.log(event.code)
    }
    onMounted(()=>{
      initGame()
      addEventListener('keydown', escListener)
    })
    onUnmounted(()=>{
      removeEventListener('keydown', escListener)
    })

    return {
      score, 
      target_hit,
      target_hit_near,
      shots_fired, 
      accuracy,
      kpm,
      updateScore, 
      updateShotsFired,

      timeLimit,
      updateTimeElapsed,
      timerElapsed,
      timerCountdown,
      timerIsForceEnd,

      // UI States and Toggle methods
      menu_main,
      menu_pause,
      menu_result,
      game_paused,
      pauseMenuUI,

      // Game State Control
      initGame,
      initFreePlay,
      initChallenge,
      resetGamemode,
      initEndResult
    }
  }
}
</script>


<style>
body {
  overflow: hidden; /* Hide scrollbars */
}
</style>