<template>
    <div class="featured-stat">
        <div class="featured-title">{{title}}</div>
        <ul class="featured-value">
            <li>
                {{value + valueEndClean}}
            </li>
            <li class="featured-trend">
                <LabelTrendStat
                    :value="trend"
                    :valueEndClean="valueEndClean"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import {computed} from '@vue/runtime-core'
import LabelTrendStat from './LabelTrendStat.vue'
export default {
    components: {LabelTrendStat},
    props:['title', 'iconURL', 'value', 'trend', 'valueEnd'],
    setup(props){   
        const titleIconContent = computed(()=>{
            return (props.iconURL != undefined)?
            "\'\'": "none"
        })
        const titleIconURL = computed(()=>{
            return (props.iconURL != undefined)?
            ('url(\"'+props.iconURL+'\")'): 'none'
        })
        const valueEndClean = computed(()=>{
            return props.valueEnd != undefined?
                    props.valueEnd:''
        })

        return {titleIconContent, titleIconURL, valueEndClean}
    }
}
</script>
<style>
.featured-stat{
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    position: static;
    display: block;
    padding: 0.2rem;
    color: #3BAEAA;
    
}

.featured-title{
    font-size: 1.4rem;
    text-align: left;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.featured-title::before{
    content: v-bind('titleIconContent');
    background-image: v-bind('titleIconURL');
    background-repeat: no-repeat;
    background-size: 1.9rem;
    min-width: 1.9rem;
    min-height: 1.9rem;
    display: block;
    /* padding-right: 0.2rem; */
    position: relative;
    left: -0.2rem;
}

.featured-value{
    font-size: 2.6rem;
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    margin: 0;
    padding: 0;
}
</style>