<template>
    <Background/>
    <h1>Player Stats</h1>
    <p>Player ID: {{$route.params.id}}</p>
</template>
<script>
import Background from "../components/Background.vue"
import { onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: 'PlayerStats',
    components: {Background},
    setup(){
        const router = useRouter()
        const route = useRoute()

        const MAX_RETRY = 5
        var counter_retry = 0

        const sleep = (sec) => {
            return new Promise(resolve => setTimeout(resolve, sec * 1000));
        }

        const xhr_request = () => { 
            // XHR Setup
            var requestBody = {'SteamID64' : route.params.id}  
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'text';
            xhr.open("POST", "http://127.0.0.1:3000/getPlayerStatsInfo", true);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.onload = function(){
                var resJSON = JSON.parse(xhr.responseText)
                console.log(resJSON)
                // If XHR returns in-progress response
                if(resJSON['code'] == 200){
                    if(resJSON['status'] == 'Task-in-Progress'){
                        console.log(resJSON['data'])
                        setTimeout(()=>{
                            xhr_request()
                            console.log('hello')
                        }, 3 *1000)                      
                        return
                    }
                    if(resJSON['status'] == 'OK'){
                        console.log(resJSON['data'])
                        return
                    }
                }   
            }
            xhr.onerror = xhrErrorHandler
            xhr.send(JSON.stringify(requestBody));
        }


        const xhrErrorHandler = ()=>{
            if(counter_retry < MAX_RETRY){
                console.log("[PlayerStats] Connection failed " + counter_retry++ + ' time(s)..')
                xhr_request()
            }
            else{
                alert("Connection Error");
            }
        }

        const exportToJsonFile = (jsonData)=>{
            var dataStr = JSON.stringify(jsonData);
            var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
            var exportFileDefaultName = 'data.json';
            var linkElement = document.createElement('a');
            linkElement.setAttribute('href', dataUri);
            linkElement.setAttribute('download', exportFileDefaultName);
            linkElement.click();
        }
        

        onMounted(()=>{
            console.log(route.params.id)
            //xhr_request()
            const eventSource = new EventSource('http://127.0.0.1:3000/getPlayerStatsInfo/' + route.params.id)
            eventSource.onmessage = function({data}){
                var resJSON = JSON.parse(data)
                console.log(resJSON)
                console.log(resJSON.data)
                if(resJSON.status == "ok"){
                    exportToJsonFile(resJSON.data)
                }
            }
            eventSource.onerror = function(){
                console.log('SSE Connection Closed')
                eventSource.close()
            }
        })
    }
}
</script>
<style>
    
</style>