<template>
    <div class="display-kd">
        <LabelFeaturedStat
            :title=title
            iconURL="/ui_icons/KillFeedKill.png"
            :value="statKD"
            :trend="statKDTrend"
        />
        <div class="display-kd-stats-kd">
        <LabelGeneralStat
            :title="getTitle(killKey)"
            :value="getValue(killKey, newStats)"
            :trend="getTrend(killKey, newStats, oldStats)"
            :rank="getRank(killKey, newStats)"
       />
       <LabelGeneralStat
            :title="getTitle(deathKey)"
            :value="getValue(deathKey, newStats)"
            :trend="getTrend(deathKey, newStats, oldStats)"
            :rank="getRank(deathKey, newStats)"
       />
       </div>
       <div class="display-kd-stats-most-kill">
       <LabelGeneralStat
            :title="getTitle(mostKillKey)"
            :value="getValue(mostKillKey, newStats)"
            :trend="getTrend(mostKillKey, newStats, oldStats)"
            :rank="getRank(mostKillKey, newStats)"
       />
       <div style="padding:0.2rem"></div> 
       </div>
    </div>
</template>
<script>
import LabelFeaturedStat from './LabelFeaturedStat.vue'
import LabelGeneralStat from './LabelGeneralStat.vue'
import { getTitle } from './statTitles'
import { getValue, getRank, getTrend } from './statUtils'

import {computed} from '@vue/runtime-core'
export default {
    components: {LabelFeaturedStat, LabelGeneralStat},
    props: {
        title:{
            default: "K/D"
        },
        mode:{},
        newStats:{},
        oldStats:{},
    },
    setup(props){   
        const killKey = computed(()=>{
            switch(props.mode){
                case 'solo':
                    return 'Kills'
                case 'duos':
                    return 'KillsDuos'
                case 'squads':
                    return 'KillsSquads'
                case 'svr32':
                    return 'KillsCustom1'
                case 'svr24':
                    return 'KillsCustom1B'
                case 'mystery':
                    return 'KillsCustom2'
                case 'zombie':
                    return 'KillsCustom3'
                case 'zombieZ':
                    return 'KillsCustom3Z'        
            }
        })
        const deathKey = computed(()=>{
            switch(props.mode){
                case 'solo':
                    return 'Deaths'
                case 'duos':
                    return 'DeathsDuos'
                case 'squads':
                    return 'DeathsSquads'
                case 'svr32':
                    return 'DeathsCustom1'
                case 'svr24':
                    return 'DeathsCustom1B'
                case 'mystery':
                    return 'DeathsCustom2'
                case 'zombie':
                    return 'DeathsCustom3'
                case 'zombieZ':
                    return 'DeathsCustom3Z'     
            }
        })
        const mostKillKey = computed(()=>{
            switch(props.mode){
                case 'solo':
                    return 'MostKills'
                case 'duos':
                    return 'MostKillsDuos'
                case 'squads':
                    return 'MostKillsSquads'
                case 'svr32':
                    return 'MostKillsCustom1'
                case 'svr24':
                    return 'MostKillsCustom1B'
                case 'mystery':
                    return 'MostKillsCustom2'
                case 'zombie':
                    return 'MostKillsCustom3'
                case 'zombieZ':
                    return 'MostKillsCustom3Z'       
            }
        })
    
        const statKD = computed(()=>{
            if(props.newStats != undefined){
                return (getValue(deathKey.value, props.newStats) > 0)?
                    (getValue(killKey.value, props.newStats) / getValue(deathKey.value, props.newStats)).toFixed(2):
                    (getValue(killKey.value, props.newStats))
            }
            return 0
        })

        const statKDTrend = computed(()=>{
            var newDeaths = 1
            var oldDeaths = 1
            // If oldStat is added
            if(props.oldStats != undefined){
                if(getValue(deathKey.value, props.newStats) > 0){newDeaths = getValue(deathKey.value, props.newStats)}
                if(getValue(deathKey.value, props.oldStats) > 0){oldDeaths = getValue(deathKey.value, props.oldStats)}
                return ((getValue(killKey.value, props.newStats)/newDeaths) - (getValue(killKey.value, props.oldStats)/oldDeaths)).toFixed(2)
            }
            return 0
        })


        return {
            killKey,
            deathKey,
            mostKillKey,

            statKD,
            statKDTrend,

            getTitle,
            getValue,
            getRank,
            getTrend}
    }
}
</script>
<style>
.display-kd{
    min-width: 300px;
    margin: 0 10px 0 10px;
    background: rgb(250,250,250, 0.75);
    padding:1.1rem;
    border-radius: 10px;
}

.display-kd > div:not(:first-child){
    margin-bottom: 0.3rem;
}

.display-kd-stats-kd{
    display: flex;
    gap: 10px
}
.display-kd-stats-kd > *{
    flex-basis: 0;
    flex-grow: 1;
}
.display-kd-stats-most-kill{
    display: flex;
    gap: 10px
}
.display-kd-stats-most-kill > *{
    flex-basis: 0;
    flex-grow: 1;
}

</style>