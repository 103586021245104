<template>
    <div class="menu_button noselect" @click.self="clicked">
        {{ text }}
    </div>
</template>

<script>
export default {
    props: ['text', 'enabled'],
    setup(props, {emit}){
        const clicked = ()=>{
            emit('clicked')
            // console.log('btn clicked')
        }
        return {clicked}
    }
}
</script>

<style>

.menu_button{
    font-family: 'Rubik', sans-serif;
    background: rgba(217, 93, 57);
    padding: 0.8rem;
    width: 8.5em;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 7px;
    transition: background-color 0.1s ease-in-out;
}

.menu_button:hover{
    background: rgb(55, 150, 84);
    color: rgb(23, 30, 52);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
