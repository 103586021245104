<template>
    <div v-if="rank != undefined && rank <= 99999 && rank >= 1" class="label-rank-background" >
        <span class="label-rank">#{{rank}}</span>
    </div>
</template>
<script>
export default {
    props: ['rank'],
    setup(props){
        
    }
}
</script>
<style>
.label-rank-background{
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    font-size: 0.7rem;
    color: rgb(250,250,250);
    
    position: static;
    display: inline-block;
    
    background: #3BAEAA;
    border-radius: 0.6rem;
    padding: 0.15rem 0.4rem 0.15rem 0.4rem;

    /* No Select on Text */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.label-rank{
    position: relative;
    top: 0.03rem;
}
</style>

