<template>
  <div id="nav">
    <router-link class="home" :to="{name: 'Home' }">
      <!-- <img src="icons/SuperAnimalRoyale-Logo-White-Trim.png" style="max-width: 8%;height: auto;"> -->
      <div class="header-title">SAR Aim Trainer</div>
    </router-link>
    <ul>
      <!-- <li>
        <router-link :to="{name: 'Search' }">Search</router-link>
      </li> -->
      <!-- <li>
        <router-link :to="{name: 'About' }">
          <span>Stats</span>
        </router-link>
      </li> -->
    </ul>
    

  </div>
</template>

<script>
export default {

}
</script>

<style>
#nav {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px;
  margin: 0;
  width: calc(100vw);
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  background: linear-gradient(
        to right bottom,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.4)
    );
  backdrop-filter: blur(10px);
  z-index: 5;
}


#nav .header-title {
  position: relative;
  /* letter-spacing: 2px; */
  padding: 0 15px;
  font-weight: bold;
  font-size: 1.3em;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 0px 20px;
}


#nav ul{
  display: flex;
  align-items: center;
  justify-content: left;
}

#nav a.router-link-exact-active {
  color: rgb(43, 151, 88);
  padding: 0;
  /* color: rgb(105, 236, 138); */
}


</style>