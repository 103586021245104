<template>
  <div class="UIStats">
      <span class="statTitle">{{statTitle}}</span>
      <span class="statValue">{{statValue}}</span>
  </div>
</template>

<script>
export default {
    props: ['statTitle', 'statValue']
}
</script>

<style>
.UIStats {
    width: 250px;
    display: flex;
    justify-content: space-between;
    font-size: 0.6rem;
}

.statValue{
    color: rgb(217, 93, 57);
}
</style>