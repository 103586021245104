<template>
    <div v-if="value != undefined && value != 0">
        <span class="trend-value">{{Math.abs(value) + valueEndClean}}</span>
    </div>
</template> 
<script>
import {computed} from '@vue/runtime-core'
export default {
    props:['value', 'valueEndClean'],
    setup(props){
        const trendIconContent = computed(()=>{
            return (props.value != undefined)?
            "\'\'": "none"
        })
        const trendIconURL = computed(()=>{
            if(props.value!= undefined){
                if(props.value > 0){
                    return 'url(\"/ui_icons/up.svg\")'
                }
                else if(props.value < 0){
                    return 'url(\"/ui_icons/down.svg\")'
                }
                else{
                    return 'none'
                }
            }
        })
        return{trendIconContent, trendIconURL}
    }
}
</script>
<style>

.trend-value{
    font-size: 0.6rem;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.trend-value::before{
    content: v-bind('trendIconContent');
    background-image: v-bind('trendIconURL');
    background-repeat: no-repeat;
    background-size: 0.5rem;
    min-width: 0.5rem;
    min-height: 0.5rem;
    display: block;
    /* padding-right: 0.2rem; */
    position: relative;
    left: -0.2rem;
}
</style>