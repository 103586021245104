<template>
    <div class="svr-stats-panel">
        <DisplayPanelTitle title="24 vs 24"/>
        <div class="svr-kd-winrate">
            <DisplayPanelKD
            mode="svr24"
            :newStats="newStats"
            :oldStats="oldStats"
            />
            <div class="svr-winrate-others-container">

                <DisplayPanelWinrateH class="winrate-panel"
                mode="svr24"
                :newStats="newStats"
                :oldStats="oldStats"
                />
                <DisplayPanelGeneralSmall class="winrate-panel"
                title="Stats"
                :statsKeyList="['FlagCapsB', 'GSNMKills']"
                :newStats="newStats"
                :oldStats="oldStats"
                />
            </div>
        </div>
        <DisplayPanelTitle title="32 Vs 32"/>
        <div class="svr-kd-winrate">
            <DisplayPanelKD
            mode="svr32"
            :newStats="newStats"
            :oldStats="oldStats"
            />
            <div class="svr-winrate-others-container">

                <DisplayPanelWinrateH class="winrate-panel"
                mode="svr32"
                :newStats="newStats"
                :oldStats="oldStats"
                />
                <DisplayPanelGeneralSmall class="winrate-panel"
                title="Stats"
                :statsKeyList="['FlagCaps']"
                :newStats="newStats"
                :oldStats="oldStats"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DisplayPanelTitle from "./DisplayPanelTitle.vue"
import DisplayPanelKD from "./DisplayPanelKD.vue"
import DisplayPanelWinrate from "./DisplayPanelWinrate.vue"
import DisplayPanelWinrateH from "./DisplayPanelWinrateH.vue"
import DisplayPanelGeneralSmall from "./DisplayPanelGeneralSmall.vue"
export default{
    components: {DisplayPanelTitle, DisplayPanelKD, DisplayPanelWinrate, DisplayPanelWinrateH, DisplayPanelGeneralSmall},
    props:['newStats', 'oldStats'],
    setup(props){

    }
}
</script>

<style>
.svr-stats-panel{
    margin: 5px 0 5px 0;
}
.svr-stats-panel > div:not(:first-child){
    margin-top: 10px;
}

.svr-kd-winrate{
  display: flex;
  margin: 0;
  padding: 0;
}

.svr-winrate-others-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px
}

.svr-panel{
    flex-grow: 1;
}
</style>