<template>
    <div class="general-stat">
        <div class="general-rank">
        <LabelRankPlate 
                :rank="rank"
            />
        </div>
        <!-- <div class="general-title-container"> -->
            <div class="general-title"> {{title}}</div>
        <!-- </div> -->

        <ul class="general-value">
            <li>
                {{value}}
            </li>
            <li>
                <LabelTrendStat
                    :value="trend"
                />
            </li>
        </ul>
        <div class="general-bar"></div>
    </div>
</template>
<script>
import LabelRankPlate from './LabelRankPlate.vue'
import LabelTrendStat from './LabelTrendStat.vue'
export default {
    components: {LabelRankPlate, LabelTrendStat},
    props:['title', 'value', 'trend', 'rank'],
    setup(props){   

        return {}
    }
}
</script>
<style>
.general-stat{
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    color: #3BAEAA;
    position: relative;
    display: block;
    padding: 0.2rem;

}

.general-title-container{
    display: flex;
    justify-content: space-between;
}

.general-rank{
    display: inline;
    position: absolute;
    top: -0.15rem;
    right: 0;
    padding:0.2rem;
    
}

.general-title{
    position: relative;
    top: 0rem;
    font-size: 0.7rem;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0.2rem;
}
.general-value{
    font-size: 1.1rem;
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    margin: 0;
    padding: 0;
}

.general-bar{
    width: 100%;
    min-height: 4px;
    background: #3BAEAA;
    border-radius: 4px;
    margin: 0;
    padding: 0;
}

</style>