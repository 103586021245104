<template>
    <div class="stats-panel-container">
        <div class="main-panel-container">
            <ul class="stat-tabs-list">
                <li v-for="(tab) in tabs" :key="tab.id">
                    <StatsPanelTabs
                        :title="tab.title"
                        :iconURL="tab.iconURL"
                        :active="isCurrentActiveTab(tab.id)"
                        @clicked="onActiveTabChange(tab.id, tab.component)"
                    />     
                </li>    
            </ul>
            <div class="secondary-panel">
                
            </div>
            <div class="main-panel" ref="mainPanel">
                <component :is="currentActiveStatsPanel" :newStats="newStats" :oldStats="oldStats"/>
                
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref, shallowRef, onUpdated} from '@vue/runtime-core'
import StatsPanelTabs from './StatsPanelTabs.vue'
import LabelFeaturedStat from './LabelFeaturedStat.vue'

import StatsPanelSolo from './StatsPanel1Solo.vue'
import StatsPanelDuos from './StatsPanel2Duos.vue'
import StatsPanelSquads from './StatsPanel3Squads.vue'
import StatsPanelSvR from './StatsPanel4SvR.vue'
import StatsPanelMystery from './StatsPanel5Mystery.vue'
import StatsPanelZombie from './StatsPanel6Zombie.vue'


export default {
    components:{StatsPanelTabs, LabelFeaturedStat, 
                StatsPanelSolo, StatsPanelDuos, StatsPanelSquads, StatsPanelSvR, StatsPanelMystery, StatsPanelZombie
                },
    props: ['newStats', 'oldStats'],
    setup(props, {emit}){
        const tabs = [
            {   id: 'solo',
                component: StatsPanelSolo,
                title: 'Solo',
                iconURL: '/ui_icons/KillFeedKill.png'},
            {   id: 'duos',
                component: StatsPanelDuos,
                title: 'Duos',
                iconURL: '/ui_icons/KillFeedKill.png'},
            {   id: 'squads',
                component: StatsPanelSquads,
                title: 'Squads',
                iconURL: '/ui_icons/KillFeedKill.png'},
            {   id: 'svr',
                component: StatsPanelSvR,
                title: 'S.A.W vs Rebellion',
                iconURL: '/ui_icons/KillFeedKill.png'},
            {   id: 'mystery',
                component: StatsPanelMystery,
                title: 'Mystery',
                iconURL: '/ui_icons/KillFeedKill.png'},
            {   id: 'zombie',
                component: StatsPanelZombie,
                title: 'Browking Dead',
                iconURL: '/ui_icons/KillFeedKill.png'}
        ]
        
        const currentActiveTab = ref('solo')
        const currentActiveStatsPanel = shallowRef(StatsPanelSolo)
        const isCurrentActiveTab = (tabID)=>{
            return currentActiveTab.value == tabID
        }
        const onActiveTabChange = (id, component)=>{
            currentActiveTab.value = id
            currentActiveStatsPanel.value = component
        }
        const mainPanel = ref(null)
        const mainPanelHeight = ref("0px")
        // const mainPanelHeight = computed(()=>{
        //     return mainPanel.value ? mainPanel.value.clientHeight+ "px" : "0px"
        // })
        
        const secondaryPanelCSSHeight = computed(()=>{
            return "calc(" + mainPanelHeight.value + " + 0.6rem)"
        })
        const mainPanelCSSOffset = computed(()=>{
            return "calc(-" + mainPanelHeight.value + " - 0.8rem)"
        })
        onUpdated(()=>{
            if(mainPanel.value){
                mainPanelHeight.value = mainPanel.value.clientHeight-4 + "px"
            }
        })


        return {tabs, currentActiveTab, currentActiveStatsPanel, onActiveTabChange, isCurrentActiveTab,
                mainPanel,
                secondaryPanelCSSHeight, mainPanelCSSOffset}

    }
    
}
</script>
<style>
.stats-panel-container{
    display:flex;
    justify-content: center;
    margin: 10px 0 10px 0;
}

.main-panel-container{
    display:inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.stat-tabs-list{
    display: inline-flex;
    position: relative;
    /* gap: 0.8rem; */
    margin: 0;
    padding: 0 10px 0 10px;
}

.stat-tabs-list li{
    margin-right: 0.8rem;

}

.secondary-panel{
    
    position: relative;
    display: block;
    min-width: 500px;
    min-height: 2rem;
    height: v-bind(secondaryPanelCSSHeight);
    top: -0.6rem;
    border-radius: 10px;
    background: rgba(220, 220, 220, 0.6);
    backdrop-filter: blur(8px);
    z-index: 10;
}

.main-panel{
    position: relative;
    top: v-bind(mainPanelCSSOffset);
    margin: 0;
    margin-bottom: v-bind(mainPanelCSSOffset);
    padding: 1rem;
    background: rgba(250, 250, 250, 0.6);
    backdrop-filter: blur(8px);
    height: auto;
    border-radius: 10px;
    z-index: 50;

    color: black;
}

</style>