<template>
  <div id="trainer">
    <canvas></canvas>
  </div>
</template>

<script>
//import {spine} from '../../spine_runtimes/spine-canvas.js'
import { ref, onMounted, onUnmounted } from '@vue/runtime-core'

// Import Target Class and constants
import Target from './ClassTarget'
const {targetWidth, targetHeight, targetSkins} = Target
// Import Decal Class
import Decal from './ClassDecal'

// Import SoundEffectPlayer
import ClassSoundEffectPlayer from './ClassSoundEffectPlayer'

import {test} from './utils'

export default {
    props: ['shots_fired', 'game_paused', 'timerIsForceEnd'],
    setup(props, { emit }){
        const addScore = (score_change, hit_type) => {
           emit('scoreUpdate', score_change, hit_type)
        }
        const addShots = () => {
           emit('shotsUpdate')
        }
        const addDeltaTime = (delta) =>{
          emit('timerUpdate', delta)
        }
        const initEndResult = ()=>{
          emit('initEndResult')
        }


        const timer_test = () => {
          const timer = setInterval(()=>{
            console.log('Hi')
            test()
          }, 1000)
        }

        const targetRadius = 25
        const fillColor = 'rgba(0,0,0,1)'
        const strokeColor = 'rgba(255,255,255,1)'
        const strokeWidth = 5


        class Circle{
          constructor(x,y,radius,fill_color,stroke_color,stroke_width){
            this.x = x
            this.y = y
            this.radius = radius
            this.fillColor = fill_color
            this.strokeColor = stroke_color
            this.strokeWidth = stroke_width
            this.score = 20
            this.texture = new Image()
            this.texture.src = "target_png/" + Math.ceil(Math.random() *targetSkins) + ".png"
            } 
          
          draw(c){
            c.beginPath()
            c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false)
            c.fillStyle = this.fillColor
            c.fill()
            c.strokeStyle = this.strokeColor
            c.lineWidth = this.strokeWidth
            c.stroke()
            c.drawImage(this.texture, this.x-this.texture.width/2, this.y-this.texture.height/2)
            c.closePath()
          }

          collided(x, y, radius){
            var x = Math.abs(this.x - x)
            var y = Math.abs(this.y - y)
            var distance = Math.sqrt(Math.pow(x,2) + Math.pow(y,2))
            if(distance < (this.radius+radius)){
              return true
            }
            else{
              return false
            }
          }

          clicked(x,y){
            if(this.collided(x,y,0)){
              // console.log("Clicked on me")
              addScore(this.score)
              return true
            }
            else{
              return false
            }
          }
        }

        
        
        const headerSize = 58
        const footerSize = 25

        const targetSpawn = (targets, radius, fill_color, stroke_color, stroke_width) =>{
          var collided = true
          while(collided){
            var x = Math.random() * (window.innerWidth-radius*2) + radius
            var y = Math.random() * (window.innerHeight-radius*2-headerSize-footerSize) + radius + headerSize
            collided = false
            for(var i = 0; i < targets.length; i++){
              if(targets[i].collided(x,y,radius)){
                collided = true
              }
            }
          }
          // Instansiate Target instance
          targets.push(new Circle(x, y, radius, fill_color, stroke_color, stroke_width))
        }

        const targetSpawn2 = (targets) =>{
          var collided = true
          var new_target = null
          while(collided){
            var x = Math.random() * (window.innerWidth-targetWidth)
            var y = Math.random() * (window.innerHeight-targetHeight-headerSize-footerSize) + headerSize
            collided = false
            new_target = new Target.Target(x,y)
            for(var i = 0; i < targets.length; i++){
              if(targets[i].collided(new_target)){
                collided = true
              }
            }
          }
          // Instansiate Target instance
          targets.push(new_target)
        }

        const entitiesUpdate = (c) =>{
          targets.value.forEach((target) =>{
            target.draw(c)
          })
          decals.value.forEach((decal,i) =>{
            (decal.alpha > 0 ) ? decal.update(c) : decals.value.splice(i,1)    
          })
        }

        const canvasResizeHandler = (targets) =>{
          var canvas = document.querySelector('canvas')
          // console.log("window resizing")
          
          


        }

        const clickHandler = (event, targets, decals) =>{
          if(props.timerIsForceEnd){
            console.log('Time is Up. Unable to shoot.')
            return
          }
          if(event.which == 1){
            //console.log("CLicked")
            // Add Decal
            decals.push(new Decal.Decal(event.clientX, event.clientY))
            addShots()
            new ClassSoundEffectPlayer.SoundEffectPlayerVar('sounds/weapons/pistol', 2, 0.1)
            // var shoot = new Audio('SAR_Magnum2(Enemy).wav')
            // shoot.volume = 0.2
            // shoot.play()
            for(var i = 0; i<targets.length;i++){
              switch(targets[i].clicked(event.clientX, event.clientY)){
                case 2: // Perfect hit
                  addScore(targets[i].score, 2)
                  targetSpawn2(targets)
                  targets.splice(i, 1)
                  new ClassSoundEffectPlayer.SoundEffectPlayerVar('sounds/effects/hit', 1, 0.5)
                  break;
                case 1: // OK hit
                  addScore(targets[i].scoreLoose, 1)
                  targetSpawn2(targets)
                  targets.splice(i, 1)
                  new ClassSoundEffectPlayer.SoundEffectPlayer('sounds/effects/okay_hit', "SAR_MalletHit_Wood.wav", 0.5)
                  break;
              }
              
                //targetSpawn(targets, targets[i].radius, targets[i].fillColor, targets[i].strokeColor, targets[i].strokeWidth)
                
              
            }
          }
          
          // targets.push(new Target(event.clientX+15, event.clientY+15, 3, 'rgba(255,0,0,1)'))
        }

        // EventListener Wrapper
        const targets = ref([])
        const decals = ref([])
        const resize = (event) => {
          canvasResizeHandler(targets.value)
        }
        const click = (event) => {
            clickHandler(event, targets.value, decals.value)
        }
        const preventRightClick = (event) => {
            event.preventDefault()
        }


        
        const initTargets = (radius) =>{
          targets.value = []
          for(var i = 0; i < 10; i++){
            targetSpawn(targets.value, radius, fillColor, strokeColor, strokeWidth)
          }
          return targets.value
        }
        const initTargets2 = () =>{
          targets.value = []
          for(var i = 0; i < 3; i++){
            targetSpawn2(targets.value)
          }
          return targets.value
        }
        
        const state = ref()
        onMounted(() => {
          // console.log("mounted")
          var lastFrameTime = Date.now() / 1000;

          // Initialize Canvas
          var canvas = document.querySelector('canvas')
          var c = canvas.getContext('2d') 
          canvas.addEventListener("mousedown", click)
          canvas.addEventListener('contextmenu', preventRightClick);

          // Initialize Cursor
          initCursor("/crosshairs/crosshair_default2.png", canvas)
          function initCursor(src_path, canvas){
            var cursor = new Image()
            cursor.onload = ()=>{
              canvas.style.cursor = "url('" + src_path + "') " + cursor.width/2 + " " + cursor.height/2 + " , auto";
            }
            cursor.src = src_path
          }

          
          //canvasResizeHandler(targets.value)
          //canvas.addEventListener("resize", resize)
          
          
          var assetManager
          var skeletonRenderer
          var skeleton, bounds

          // skeletonRenderer = new spine.canvas.SkeletonRenderer(c)
          // skeletonRenderer.debugRendering = true;
          // skeletonRenderer.triangleRendering = true;
          // var skelName = "Character_full"
          // var animName = "emotes/emote_defaultdance";

          var Texture = class {
          constructor(image) {
            if(typeof image === "Image"){
              this._image = image;
            }
            else{
              this._image = new Image();
              this._image.src = image
            }
            
          }
          getImage() {
            return this._image;
          }
        };
          var CanvasTexture = class extends Texture {
            constructor(image) {
              super(image);
            }
            setFilters(minFilter, magFilter) {
            }
            setWraps(uWrap, vWrap) {
            }
            dispose() {
            }
          };

          // assetManager = new spine.canvas.AssetManager("spine_character/")
          // assetManager.loadText(skelName + ".json");
          // assetManager.loadText(skelName + ".atlas");
          // assetManager.loadTexture(skelName+".png");
          // for(var i = 2; i <= 7; i++){
          //   assetManager.loadTexture(skelName+ i +".png");
          // }


          // var skelName = "spineboy"
          // var animName = "walk";
          // assetManager = new spine.AssetManager("spine_character/")
          // assetManager.loadText(skelName + ".json");
          // assetManager.loadText(skelName + ".atlas");
          // assetManager.loadTexture(skelName+".png");
          // console.log(assetManager)
          
          //targets.value = initTargets(targetRadius)

          // Init Targets
          targets.value = initTargets2()

          function loadSkeleton (name, initialAnimation, skin) {
            if (skin === undefined) skin = "default"

            // Load the texture atlas using name.atlas and name.png from the AssetManager.
            // The function passed to TextureAtlas is used to resolve relative paths.
            var atlas = new spine.TextureAtlas(assetManager.get(name+".atlas"), (image)=>{return new CanvasTexture(assetManager.pathPrefix+image)})
            // atlas.setTextures(assetManager)
            console.log(atlas)
            

            // Create a AtlasAttachmentLoader, which is specific to the WebGL backend.
            var atlasLoader = new spine.AtlasAttachmentLoader(atlas)

            // Create a SkeletonJson instance for parsing the .json file.
            var skeletonJson = new spine.SkeletonJson(atlasLoader)

            // Set the scale to apply during parsing, parse the file, and create a new skeleton.
            var skeletonData = skeletonJson.readSkeletonData(assetManager.get(name + ".json"))
            var skeleton = new spine.Skeleton(skeletonData)
            skeleton.scaleY = -1
            var bounds = calculateBounds(skeleton)
            skeleton.setSkinByName(skin)
            

            // Create an AnimationState, and set the initial animation in looping mode.
            var animationState = new spine.AnimationState(new spine.AnimationStateData(skeleton.data))
            animationState.setAnimation(0, initialAnimation, true)
            animationState.addListener({
              event: function(trackIndex, event) {
                //console.log("Event on track " + trackIndex + ": " + JSON.stringify(event));
              },
              complete: function(trackIndex, loopCount) {
                // console.log("Animation on track " + trackIndex + " completed, loop count: " + loopCount);
              },
              start: function(trackIndex) {
                //console.log("Animation on track " + trackIndex + " started");
              },
              end: function(trackIndex) {
                //console.log("Animation on track " + trackIndex + " ended");
              }
            })

            // Pack everything up and return to caller.
            return { skeleton: skeleton, state: animationState, bounds: bounds };
          }

          function calculateBounds(skeleton) {
            var data = skeleton.data;
            skeleton.setToSetupPose();
            skeleton.updateWorldTransform();
            console.log(skeleton)
            var offset = new spine.Vector2();
            var size = new spine.Vector2();
            skeleton.getBounds(offset, size, []);
            return { offset: offset, size: size };
          }

          const spineResize = (targets) =>{
            var w = window.innerWidth
            var h = window.innerHeight
            if(canvas.width != w || canvas.height !=h){
              canvas.width = w
              canvas.height = h
            }
            // console.log("x:"+window.innerWidth+", y:"+window.innerHeight)
            for(var i =0; i<targets.length;i++){
              if(targets[i].x > canvas.width-targets[i].radius || targets[i].y > canvas.height-targets[i].radius-footerSize){
                targetSpawn(targets, targets[i].radius, targets[i].fillColor, targets[i].strokeColor, targets[i].strokeWidth)
                targets.splice(i, 1)
              }
            }

            // magic

            // var centerX = bounds.offset.x + bounds.size.x / 2;
            // var centerY = bounds.offset.y + bounds.size.y / 2;
            // var scaleX = bounds.size.x / canvas.width;
            // var scaleY = bounds.size.y / canvas.height;
            // var scale = Math.max(scaleX, scaleY) * 1.2;
            // if (scale < 1) scale = 1;
            // var width = canvas.width * scale;
            // var height = canvas.height * scale;


            // c.setTransform(1, 0, 0, 1, 0, 0);
            // c.scale(1 / scale, 1 / scale); 
            // c.translate(-centerX, -centerY);
            // c.translate(width / 2, height / 2);

          }

          // function load () {
          //   if (assetManager.isLoadingComplete()) {
          //     var data = loadSkeleton(skelName, animName, 'Fox/Fox');
          //     skeleton = data.skeleton;
          //     state.value = data.state;
          //     bounds = data.bounds;
          //     //skeleton.setToSetupPose()
          //     requestAnimationFrame(render);
          //   } else {
          //     requestAnimationFrame(load);
          //   }
          // }

          function render(){
            var now = Date.now() / 1000;
            var delta = now - lastFrameTime;

            //Game Logic and time
            if(!props.game_paused && props.shots_fired > 0){
              props.timerIsForceEnd ? initEndResult():addDeltaTime(delta)
            }
            //console.log(delta)
            lastFrameTime = now;
            
            // Render Game Scene
            spineResize(targets.value)
            c.save();
            c.setTransform(1, 0, 0, 1, 0, 0);
            c.clearRect(0,0, canvas.width, canvas.height)
            entitiesUpdate(c);
            c.restore();

            // state.value.update(delta);
            // state.value.apply(skeleton);
            // skeleton.updateWorldTransform();
            // skeletonRenderer.draw(skeleton);

            // c.beginPath()
            // c.rect(bounds.offset.x, bounds.offset.y, bounds.size.x, bounds.size.y)
            // c.stroke()
            // c.closePath()

            // c.save();
            // c.strokeStyle = "green";
            // c.beginPath();
            // c.moveTo(-1000, 0);
            // c.lineTo(1000, 0);
            // c.moveTo(0, -1000);
            // c.lineTo(0, 1000);
            // c.stroke();
            // c.restore();

            

            requestAnimationFrame(render);
          }
          
          // load()
          render()
          // timer_test()

        })

        onUnmounted(() =>{
          // console.log("unmounted")
          removeEventListener("resize", resize)
          removeEventListener("mousedown", click)
          removeEventListener('contextmenu', preventRightClick);
          // state.value.clearListeners()
          //console.log("removing listener")
        })

        return { addScore }


    }
}
</script>

<style>
#trainer canvas{
  border: 0px solid black;
  /* cursor: url('/cross_small.png'), auto; */
}

#trainer{
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    z-index: -1;
}

body{
  margin: 0;
  padding: 0;
}
</style>