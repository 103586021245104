<template>
    <div class="mystery-stats-panel">
        <!-- <DisplayPanelTitle title="Mystery"/> -->
        <div class="mystery-kd-winrate">
            <DisplayPanelKD
            mode="mystery"
            :newStats="newStats"
            :oldStats="oldStats"
            />
            <DisplayPanelWinrate class="winrate-panel"
            mode="mystery"
            :newStats="newStats"
            :oldStats="oldStats"
            />
        </div>
    </div>
</template>

<script>
import DisplayPanelTitle from "./DisplayPanelTitle.vue"
import DisplayPanelKD from "./DisplayPanelKD.vue"
import DisplayPanelWinrate from "./DisplayPanelWinrate.vue"
export default{
    components: {DisplayPanelTitle, DisplayPanelKD, DisplayPanelWinrate},
    props:['newStats', 'oldStats'],
    setup(props){

    }
}
</script>

<style>
.mystery-stats-panel{
    margin: 5px 0 5px 0;
}
.mystery-stats-panel > div:not(:first-child){
    margin-top: 10px;
}
.mystery-kd-winrate{
  display: flex;
  padding: 0;
}
.mystery-panel{
    flex-grow: 1;
}
</style>