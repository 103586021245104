function getValue(key ,data){
    if(data != undefined){
        return data[key] != undefined?
             data[key]['Value']:0}
    return 0
}

function getRank(key ,data){
    if(data != undefined){
        return data[key] != undefined?
             data[key]['UserRank']:-1}
    return -1
}

function getTrend(key, newData, oldData){
    // If oldStat is added
    if(oldData != undefined){
        // If key exist in oldStat
        return oldData[key] != undefined?
            getValue(key, newData) - getValue(key, oldData):
            getValue(key, newData) 
    }
    // If oldStat not added
    return 0
}

export {getValue, getRank, getTrend}