const targetWidth = 38.88
const targetHeight = 67.2

const targetHitboxColor = 'rgba(0,255,0,1)'
const targetHitboxColorLoose = 'rgba(255,0,0,1)'
const targetHitboxOutlineWidth = 2

const targetDefaultScore = 20

const targetSkins = 24
const textures = []
for(var i =0; i < targetSkins; i++){
  textures.push(new Image())
  textures[i].src = "target_png/" + (i+1) + ".png"
}


class Target{
    constructor(x, y){ 
        this.x = x
        this.y = y
        this.width = targetWidth
        this.height = targetHeight



        this.hitboxColor = targetHitboxColor
        this.hitboxOutlineWidth = targetHitboxOutlineWidth

        // Loose Hitbox
        this.hitboxColorLoose = targetHitboxColorLoose
        this.hitboxSizeOffsetLoose = 5

        this.score = targetDefaultScore
        this.scoreLoose = targetDefaultScore/2

        this.skinID = Math.ceil(Math.random() * targetSkins)

        this.texture = textures[this.skinID-1]
        //this.texture = new Image()
        //this.texture.src = "target_png/" + Math.ceil(Math.random() *targetSkins) + ".png"
    }

    draw(c){
      c.beginPath()
      c.drawImage(this.texture, this.x-(this.texture.width-this.width)/2, this.y-(this.texture.height-this.height))
      c.strokeStyle = this.hitboxColor
      c.lineWidth = this.hitboxOutlineWidth
      c.rect(this.x, this.y, this.width,this.height);
      c.stroke()
      c.closePath()
      // Draw loose hitbox
      // c.save()
      // c.strokeStyle = this.hitboxColorLoose
      // c.beginPath()
      // c.arc(this.x + this.width/2, this.y+this.height/2, this.height/2+this.hitboxSizeOffsetLoose, 0, Math.PI * 2, false)
      // c.stroke()
      // c.closePath()
      // c.restore()
    }

    collided(otherTarget){
      if(this.x < otherTarget.x + otherTarget.width &&
         this.x + this.width > otherTarget.x &&
         this.y < otherTarget.y + otherTarget.height &&
         this.y + this.height > otherTarget.y){
        return true
      }
      else{
        return false
      }
    }

    clicked(x,y){
      if((x >= this.x && x <= this.x + this.width)&&
         (y >= this.y && y <= this.y + this.height)){
        // console.log("Clicked on me")
        return 2
      }
      else{
        // Loose hitbox判定
        var x = Math.abs((this.x+this.width/2) - x)
        var y = Math.abs((this.y+this.height/2) - y)
        var distance = Math.sqrt(Math.pow(x,2) + Math.pow(y,2))
        if(distance < (this.height/2+this.hitboxSizeOffsetLoose)){
          return 1
        }
        else{
          return 0
        }
      }
    }
  }

export default {Target, targetWidth:targetWidth, targetHeight:targetHeight, targetSkins:targetSkins}
