<template>  
    <div class="solo-stats-panel">
        <!-- <DisplayPanelTitle title="Solo"/> -->
        <div class="solo-kd-winrate">
        <DisplayPanelKD
            mode="solo"
            :newStats="newStats"
            :oldStats="oldStats"
            />
            <DisplayPanelWinrate class="winrate-panel"
            mode="solo"
            :newStats="newStats"
            :oldStats="oldStats"
            />
        </div>
        <div>
        <DisplayPanelGeneralSmall
            title="Challenges" :newStats="newStats" :oldStats="oldStats"
            :statsKeyList="['PacifistVictoriesSolo', 'NoArmorVictoriesSolo', 'MeleeVictoriesSolo']"
        />
        </div>
    </div>
</template>

<script>
import DisplayPanelTitle from "./DisplayPanelTitle.vue"
import DisplayPanelKD from "./DisplayPanelKD.vue"
import DisplayPanelWinrate from "./DisplayPanelWinrate.vue"
import DisplayPanelGeneralSmall from './DisplayPanelGeneralSmall.vue'
export default{
    components: {DisplayPanelTitle, DisplayPanelKD, DisplayPanelWinrate, DisplayPanelGeneralSmall},
    props:['newStats', 'oldStats'],
    setup(props){

    }
}
</script>

<style>
.solo-stats-panel{
    margin: 5px 0 5px 0;
}
.solo-stats-panel > div:not(:first-child){
    margin-top: 10px;
}
.solo-kd-winrate{
  display: flex;
  padding: 0;
}
.winrate-panel{
    flex-grow: 1;
}
</style>