const decalRadius = 3
const decalColor = 'rgba(0,0,0,0.5)'
const decalColor_miss = 'rgba(255,0,0,0.5)'

const clamp = (num, min, max) =>{
  return Math.min(Math.max(num, min), max)
}


class Decal{
  constructor(x,y){
    this.x = x
    this.y = y
    this.radius = decalRadius
    this.color = decalColor
    this.alpha = 1.5
    } 
  
  draw(c){
    c.save()
    c.globalAlpha = clamp(this.alpha, 0, 1)
    c.beginPath()
    c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false)
    c.fillStyle = this.color
    c.fill()
    c.closePath()
    c.restore()
  }

  update(c){
    this.draw(c)
    this.alpha -= 0.01
  }
}

export default {Decal}
