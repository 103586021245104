<template>
  <div id="footer">
    <ul id="stats">
        <li>Score: {{score}}</li>
        <li>Accuracy: {{accuracy}} ({{target_hit_total}}/{{shots_fired}})</li>
        <li>KPM: {{kpm}}</li>
    </ul>
    <ul id="misc">
      <li><span class="time">Time Left: {{timerCountdown}}</span></li>
        <li><span class="time">Time Elapsed: {{timerElapsed}}</span></li>
        <li><span class="version">Version 0.01</span></li>
    </ul>
    
  </div>
</template>

<script>
import {computed} from 'vue'
export default {
    props: ['score', 'shots_fired', 'target_hit', 'target_hit_near', 'accuracy', 'kpm', 'timerElapsed', 'timerCountdown'],
    setup(props){
      const target_hit_total = computed(()=>{
        return props.target_hit + props.target_hit_near*0.5
      })
      return {target_hit_total}
    }
    
}
</script>

<style>
#footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
        to right bottom,
        rgba(26, 26, 26, 0.7),
        rgba(0, 0, 0, 0.55)
    );
  backdrop-filter: blur(10px);

  font-size: 0.5em;
}

#footer ul{
    padding: 0px;
    display: flex;
}

#footer #stats li {
    list-style: none;
    padding-left: 10px;
}

#footer #misc li{
    list-style: none;
    padding-right: 10px;
}
</style>