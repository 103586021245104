<template>
  <Background/>
  <Header/>
  <UISearchbar
  hint="https://steamcommunity.com/id/zzdev"
  />
  
</template>


<script>
import Background from "../components/Background.vue"
import Header from "../components/Header.vue"
import UISearchbar from "../components/UIComponents/UISearchbar.vue"
import { onMounted } from '@vue/runtime-core'

export default {
  components: { Background, Header, UISearchbar},
  setup(){
    onMounted(()=>{
 
    })

  }
}
</script>

<style>


</style>