<template>
  <div class="menu_background"/>
  <div id="menu">
      <ul class="menu_items">
          <li><img src="icons/SuperAnimalRoyale-Logo-White-Trim.png" style="max-width: 30%;height: 80%;"></li>
          <li><div style="font-weight: 800; font-size: 3em;">AIM TRAINER</div></li>
          <li><MenuButton text="FREE PLAY" @clicked="startFreePlay"/></li>
          <li><MenuButton text="CHALLENGE" @clicked="startChallenge"/></li>
          <li><MenuButton text="SETTINGS"/></li>
          <li>
            <div style="font-weight: bold;  font-size: 1em; 
            color: rgba(255, 255, 255, 0.4); padding-top: 30px;">
                Early Development Prototpye by zzdev
            </div>
          </li>
          <li>
            <div style="font-weight: bold; font-size: 1em; 
            color: rgba(255, 255, 255, 0.4);">
                Version 0.0.1
            </div>
          </li>
      </ul>
  </div>
</template>

<script>
import MenuButton from './MenuButton.vue'
export default {
    components:{MenuButton},
    setup(props, {emit}){
        const startFreePlay = () =>{
            emit('startFreePlay')
        }
        const startChallenge = () =>{
            emit('startChallenge')
        }
        return {startFreePlay, startChallenge}
    }
}
</script>

<style>
.menu_background {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(3px);
    z-index: 3;
}
#menu{
    position:fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    
}
.menu_items{
    margin:0;
    padding:0;
}
.menu_items li{
    margin: 10px;
    display: flex;
    justify-content: center;
}

button{
    width: 6em;
    margin: 5px;
}

</style>