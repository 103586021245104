<template>
    <div class="result_button" @click.self="clicked">
        {{ text }}
    </div>
</template>

<script>
export default {
    props: ['text', 'enabled'],
    setup(props, {emit}){
        const clicked = ()=>{
            emit('clicked')
            // console.log('btn clicked')
        }
        return {clicked}
    }
}
</script>

<style>
.result_button{
    font-family: 'Rubik', sans-serif;
    background: rgba(217, 93, 57);
    padding: 1rem;
    width: 9.5em;
    border-radius: 7px;
    font-size: 1.2rem;
    font-weight: bold;

    /* No Select on Text */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.result_button:hover{
    background: rgb(51, 151, 101);
    color: rgb(23, 30, 52);
}
</style>
