<template>
    <div class="resultBackground"></div>   
  <transition name="bounce">
  <div id="result" class="noselect">
    <div id="resultTitle">RESULTS</div>
    <div id="resultBarTitle">YOUR RANK IS:</div>
    <div id="resultBarTitle2">
        <ul>
            <li>{{rankCurrent[0]}}</li>
            <li>{{rankCurrent[1]}}</li>
        </ul>
        
        </div>
    <div id="resultBar" :style="'--width:'+progress"></div>
    <div id="resultBarProgress"><span class="resultHighlight">{{score}}</span> / <span class="resultProgress2">{{rankCurrent[3]}}</span></div>
      <ul id="resultStats">
          <li><UIStats statTitle="TIME ELAPSED" :statValue="timerElapsed" /></li>
          <li><UIStats statTitle="PERFECT SHOT" :statValue="target_hit" /></li>
          <li><UIStats statTitle="OKAY SHOT" :statValue="target_hit_near" /></li>
          <li><UIStats statTitle="MISS" :statValue="statMissed" /></li>
          <li><UIStats statTitle="ACCURACY" :statValue="accuracy" /></li>
          <li><UIStats statTitle="KILLS/MINUTE" :statValue="kpm" /></li>
      </ul>
    <div id="resultStat2">
        SCORE:  <span class="resultHighlight">{{score}}</span>
    </div>
    <div id="resultButtons">
          <ResultButton text="RETURN TO MENU" @clicked="backToMenu"/>
    </div>
  </div>
  </transition>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import ResultButton from './ResultButton.vue'
import UIStats from '../UIComponents/UIStats.vue'
export default {
    components:{ResultButton, UIStats},
    props:['timerElapsed', 'target_hit', 'target_hit_near', 'accuracy', 'kpm', 'score', 'shots_fired'],
    emits:['backToMenu'],
    setup(props, {emit}){
        const backToMenu = () =>{
            emit('backToMenu')
        }
        const statMissed = computed(()=>{
            return props.shots_fired - props.target_hit - props.target_hit_near
        })

        const rankTitles = [
            'C-', 'C', 'C+', 
            'B-', 'B', 'B+', 
            'A-', 'A', 'A+', 'A++', 
            'S-', 'S', 'S+', 'S++', 
            'SS-', 'SS', 'SS+',
            'TOP RANK']
        const rankRequirements = [
            0, 500, 800, 
            1100, 1400, 1700, 
            2000, 2400, 2600, 2800, 
            3000, 3100, 3200, 3300, 
            3400, 3500, 3600]
        
        const rankCurrent = ref([])
        const rankHasSet = ref(false)
            for(var i = 1; i < rankRequirements.length; i++){
                if(props.score < rankRequirements[i]){
                    rankCurrent.value =  [rankTitles[i-1], rankTitles[i], rankRequirements[i-1], rankRequirements[i]]
                    rankHasSet.value = true
                    break;
                }
            }
            if(!rankHasSet.value){
                rankCurrent.value =[
                    rankTitles[rankTitles.length-2], 
                    rankTitles[rankTitles.length-1], 
                    rankRequirements[rankRequirements.length-2], 
                    rankRequirements[rankRequirements.length-1]
                ]
            }


        //Prgress bar animation
        const progress = ref(0)
        const progress_end = ref((props.score - rankCurrent.value[2])/(rankCurrent.value[3] - rankCurrent.value[2]))
        onMounted(()=>{
            //Progress bar animation
            var lastFrameTime = 0
            var lastProgressValue = -1
            var lerpSmoothingFactor = 0.2
            
            const Math_lerp = (value1, value2, amount) =>{
                amount = amount < 0 ? 0 : amount;
                amount = amount > 1 ? 1 : amount;
                return value1 + (value2 - value1) * amount;
            }
            function renderProgressAnimation(){
                var now = Date.now() / 1000
                var delta = now - lastFrameTime
                lastFrameTime = now
                
                // Lerping: Exponential Smoothing
                var t = 1 - Math.pow(lerpSmoothingFactor, delta)
                progress.value = Math_lerp(progress.value, progress_end.value, t)
                if((progress.value - lastProgressValue) < 0.00001){
                    progress.value = progress_end.value
                }
                else{
                    lastProgressValue = progress.value
                    requestAnimationFrame(renderProgressAnimation)
                }
            }
            
            lastFrameTime = Date.now() / 1000;
            renderProgressAnimation()
        })

        return {backToMenu, statMissed, rankCurrent, progress}
    }
}
</script>

<style>
.resultBackground {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(3px);
    z-index: 3;
}

#result {
    font-family: 'Rubik', sans-serif;
    position: absolute;
    min-width: 600px;
    bottom:0%;
    left: 50%;
    transform: translate(-50%, -15%);
    background: rgba(0,0,0,0.6);
    backdrop-filter: blur(3px);
    padding: 1rem 1.6rem;
    border-radius: 0.8em;
    z-index: 3;
}

#resultTitle {
    font-size: 2.2rem;
    font-weight: bold;
    text-align: left;
    margin: 0.6rem 0;
}
#resultBarTitle{
    text-align: left;
    font-size: 0.8rem;
    font-weight: bold;
}
#resultBarTitle2 ul{
    font-size: 1.8rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

#resultBar{
    min-height: 4px;
    background: white;
    margin: 0.3rem auto 0.3rem auto;
    text-align: left;
    border-radius: 2px;
}
#resultBar::before{
    content: '';
    min-height: 4px;
    width: calc(calc(100% - 3.2rem)*var(--width, 0));
    max-width: calc(100% - 3.2rem);
    background: rgb(217, 93, 57);
    position: absolute;
    text-align: left;
    border-radius: 2px;
}
#resultBarProgress{
    text-align: right;
    font-size: 0.8rem;
}


#resultStats{
    margin: 1.2rem auto;
    padding:0;
}
#resultStats ul{
    margin: 0rem auto;
}
#resultStats li{
    margin: 0.2rem auto;
}
#resultStat2{
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1.2rem 0 0.6rem 0;
}
.resultHighlight{
    color: rgb(217, 93, 57);;
}
#resultButtons{
    justify-content: center;
    display: flex;
}

.noselect *{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

</style>